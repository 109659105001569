export default [
    {"name": "English (en)", "code": "en"},
    {"name": "Spanish (es)", "code": "es"},
    {"name": "French (fr)", "code": "fr"},
    {"name": "Chinese (zh)", "code": "zh"},
    {"name": "Tagalog (tl)", "code": "tl"},
    {"name": "Arabic (ar)", "code": "ar"},
    {"name": "Vietnamese (vi)", "code": "vi"},
    {"name": "Portuguese (pt)", "code": "pt"},
    {"name": "German (de)", "code": "de"},
    {"name": "Korean (ko)", "code": "ko"},
    {"name": "Russian (ru)", "code": "ru"},
    {"name": "Hindi (hi)", "code": "hi"},
    {"name": "Italian (it)", "code": "it"},
    {"name": "Urdu (ur)", "code": "ur"},
    {"name": "Persian (fa)", "code": "fa"},
    {"name": "Polish (pl)", "code": "pl"},
    {"name": "Greek (el)", "code": "el"},
    {"name": "Japanese (ja)", "code": "ja"},
    {"name": "Punjabi (pa)", "code": "pa"},
    {"name": "Ukrainian (uk)", "code": "uk"},
    {"name": "Gujarati (gu)", "code": "gu"},
    {"name": "Hebrew (he)", "code": "he"},
    {"name": "Bengali (bn)", "code": "bn"},
    {"name": "Tamil (ta)", "code": "ta"},
    {"name": "Turkish (tr)", "code": "tr"},
    {"name": "Swahili (sw)", "code": "sw"},
    {"name": "Dutch (nl)", "code": "nl"},
    {"name": "Bosnian (bs)", "code": "bs"},
    {"name": "Croatian (hr)", "code": "hr"},
    {"name": "Armenian (hy)", "code": "hy"},
    {"name": "Romanian (ro)", "code": "ro"},
    {"name": "Serbian (sr)", "code": "sr"},
    {"name": "Czech (cs)", "code": "cs"},
    {"name": "Swedish (sv)", "code": "sv"},
    {"name": "Norwegian (no)", "code": "no"},
    {"name": "Danish (da)", "code": "da"},
    {"name": "Hungarian (hu)", "code": "hu"},
    {"name": "Slovak (sk)", "code": "sk"},
    {"name": "Slovenian (sl)", "code": "sl"},
    {"name": "Macedonian (mk)", "code": "mk"},
    {"name": "Latvian (lv)", "code": "lv"},
    {"name": "Lithuanian (lt)", "code": "lt"},
    {"name": "Estonian (et)", "code": "et"},
    {"name": "Afrikaans (af)", "code": "af"},
    {"name": "Galician (gl)", "code": "gl"},
    {"name": "Welsh (cy)", "code": "cy"},
    {"name": "Icelandic (is)", "code": "is"},
    {"name": "Finnish (fi)", "code": "fi"},
    {"name": "Maori (mi)", "code": "mi"},
    {"name": "Kannada (kn)", "code": "kn"},
    {"name": "Nepali (ne)", "code": "ne"},
    {"name": "Kazakh (kk)", "code": "kk"},
    {"name": "Marathi (mr)", "code": "mr"},
    {"name": "Azerbaijani (az)", "code": "az"},
    {"name": "Malay (ms)", "code": "ms"},
    {"name": "Thai (th)", "code": "th"}
]